.FooterFullScreen{
    overflow: hidden;
    padding: 3rem 0;
    position: relative;
    box-sizing: border-box;
    background-color: rgb(48, 48, 48);
}

.FooterContainer{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    display: flex;
    padding-bottom: 50px;
    border-bottom: 1px solid #0c0c0c;
}

.Copyright{
    width: 40%;
    display: flex;
}

.FootIcon{
    display: flex;
    align-items: center;
}

.FootIcon img{
    border: 4px solid rgb(126, 126, 126);
    border-radius: 50%;
    width: 70px;
    object-fit: cover;
    margin: 0;
    box-shadow: 0px 0px 8px 2px rgb(161, 161, 161)
}

.FootIcon h4{
    margin: 0;
    padding-left: 20px;
    font-family: Poppins, sans-serif;
    color:  rgb(255, 255, 255);
}

.QuickGroup{
    width: 60%;
}

.QuickGroup h4{
    color: rgb(86, 173, 255);
    font-weight: 500;
}

.QuickLink{
    display: flex;
    justify-content: space-between;
}

.QuickLink a{
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-family: Poppins, sans-serif;
}

.QuickLink a:hover{
    color:rgb(86, 173, 255);
    transition: all 0.1s ease-out; /* vendorless fallback */
    -o-transition: all 0.1s ease-out; /* opera */
    -ms-transition: all 0.1s ease-out; /* IE 10 */
    -moz-transition: all 0.1s ease-out; /* Firefox */
    -webkit-transition: all 0.1s ease-out; /*safari and chrome */
    
}

.shareGroupFoot{
    display: flex;
    margin-left: 30px;
}

.shareGroupFoot button{
    position: relative;
    top: 0px;
    margin-top: 20px;
    margin-right: 25px;
    border: 0px;
    background:linear-gradient(#525252,rgb(48, 48, 48));
    border-radius: 5px;
    box-shadow: 0px 0px 8px 4px #474747;
    padding: 10px;
    overflow: hidden;
    width: 50px;
    height: 55px;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.shareGroupFoot i {
    font-size: 30px;
    color: rgb(86, 173, 255);
    object-fit: cover;
}

.shareGroupFoot button:hover{
    position: relative;
    top: -10px;
    background:linear-gradient(#757575,rgb(71, 71, 71));
    box-shadow: 0px 0px 12px 7px #646464;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}


.FooterTwoContainer{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    display: flex;
    border-bottom: #474747;
    justify-content: center;
}

.FooterTwoContainer p{
    font-size: 20px;
    color: #d8d8d8;
    font-family: Poppins, sans-serif;
    margin: 0;
}

@media (max-width: 820px){

    .FooterContainer{
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-left: 20px;
    }

    .Copyright{
        width: 100%;
    }

    .QuickGroup{
        width: 100%;
        margin-top: 20px;
    }

    .QuickLink{
        flex-direction: column;

    }

    .QuickLink a{
        margin-bottom: 10px;
    }

    .FootIcon img{
        border: 4px solid rgb(126, 126, 126);
        border-radius: 50%;
        width: 40px;
        object-fit: cover;
        margin: 0;
        box-shadow: 0px 0px 8px 2px rgb(161, 161, 161)
    }
}