.ContentMeFullScreen{
    overflow: hidden;
    padding: 6.5rem 0;
    position: relative;
    box-sizing: border-box;
    background-color: white;
}

.contentMeCon{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
}

.contentTitle{
    display: flex;
    FLEX-WRAP:wrap;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.contentTitle p{
    color: rgb(86, 173, 255);
    font-size: 15px;
}

.contentTitle h2{
    font-size:50px;
    margin-top: 0;
}

.contentMeGroup{
    display:flex;
    padding: 10px;
    width: 100%;
}

.meInfoGroup{
    box-shadow: 0px 0px 8px 4px rgb(226, 226, 226);
    border-radius: 15px;
    padding: 20px;
    width: 30%;
    margin-right: 30px;
    background: linear-gradient(white,rgb(243, 243, 243),white);
}

.meInfoGroup img{
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.shareGroup{
    display: flex;
}

.shareGroup button{
    position: relative;
    top: 0px;
    margin-top: 20px;
    margin-right: 25px;
    border: 0px;
    background:linear-gradient(#ffffff,#ffffff);
    border-radius: 10px;
    box-shadow: 0px 0px 8px 4px rgb(238, 238, 238);
    padding: 10px;
    overflow: hidden;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.shareGroup i {
    font-size: 40px;
    color: rgb(86, 173, 255);
    object-fit: cover;
}

.shareGroup button:hover{
    position: relative;
    top: -10px;
    background: linear-gradient(#ffffff,#f7f7f7);
    box-shadow: 0px 0px 12px 7px rgb(238, 238, 238);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.meInputGroup{
    padding: 15px;
    box-shadow: 0px 0px 8px 4px rgb(226, 226, 226);
    border-radius: 15px;
    padding: 30px;
    width: 70%;
    background: linear-gradient(white,rgb(243, 243, 243),white);
}

.firstDataGroup{
    display: flex;
    width: 100%;
}

.firstDataGroup .ContentMeData{
    width: 50%;
}

.ContentMeData{
    padding-right: 20px;
    padding-left: 10px;
}

.ContentMeData p{
    cursor: default;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;;
    font-size: 15px;
    font-weight: 600;
    color: #6e6e6e;
}

.ContentMeData input{
    width: 100%;   
    height: 45px;
    font-size: 25px;
}

.ContentMeDataMessage{
    padding-right: 20px;
    padding-left: 10px;
}

.ContentMeDataMessage textarea{
    width: 100%;
    height: 300px;
    font-size: 25px;
}

.ContentMeDataMessage p{
    cursor: default;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #6e6e6e;
}

.MessageConfirm{
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 15px;
    height: 50px;
}

.MessageConfirm button{
    width: 100%;
    height: 50px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    border: 0;
    color: #6e6e6e;
    box-shadow: 0px 0px 0px 0px rgb(228, 228, 228);
    border: 0px solid rgb(86, 173, 255);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.MessageConfirm button:hover{
    color: rgb(86, 173, 255);
    box-shadow: 0px 0px 8px 4px rgb(238, 238, 238);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.MessageConfirm button:active{
    color: rgb(86, 173, 255);
    border: 2px solid rgb(86, 173, 255);
    transition: all 0.1s ease-out; /* vendorless fallback */
    -o-transition: all 0.1s ease-out; /* opera */
    -ms-transition: all 0.1s ease-out; /* IE 10 */
    -moz-transition: all 0.1s ease-out; /* Firefox */
    -webkit-transition: all 0.1s ease-out; /*safari and chrome */
}

.meInputGroup input{
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(190, 190, 190);
    border-radius: 10px;
}

.meInputGroup input:focus{
    outline: none !important;
    border:2px solid rgb(86, 173, 255);
    transition: all 0.1s ease-out; /* vendorless fallback */
    -o-transition: all 0.1s ease-out; /* opera */
    -ms-transition: all 0.1s ease-out; /* IE 10 */
    -moz-transition: all 0.1s ease-out; /* Firefox */
    -webkit-transition: all 0.1s ease-out; /*safari and chrome */
}

.meInputGroup textarea{
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(190, 190, 190);
    border-radius: 10px;
}

.meInputGroup textarea:focus{
    outline: none !important;
    border:2px solid rgb(86, 173, 255);
    transition: all 0.1s ease-out; /* vendorless fallback */
    -o-transition: all 0.1s ease-out; /* opera */
    -ms-transition: all 0.1s ease-out; /* IE 10 */
    -moz-transition: all 0.1s ease-out; /* Firefox */
    -webkit-transition: all 0.1s ease-out; /*safari and chrome */
}

.MessageCorrent{
    margin: 10px 10px;
}

.MessageCorrent i, .MessageCorrent span{
    color: green;
    margin-right: 10px;
    transition: all 0.1s ease-out; /* vendorless fallback */
    -o-transition: all 0.1s ease-out; /* opera */
    -ms-transition: all 0.1s ease-out; /* IE 10 */
    -moz-transition: all 0.1s ease-out; /* Firefox */
    -webkit-transition: all 0.1s ease-out; /*safari and chrome */
}

.meInfoGroup span{
    color: #6e6e6e;
    font-weight: 600;
}

@media (max-width: 820px){
    .contentTitle h2{
        font-size: 25px;
    }

    .contentMeGroup{
        flex-wrap: wrap;
    }

    .meInfoGroup{
        width: 100%;
        margin-right: 20px;
    }

    .meInputGroup{
        width: 100%;
        margin-right: 20px;
        margin-top: 30px;
    }

    .firstDataGroup{
        flex-wrap: wrap;
    }

    .firstDataGroup .ContentMeData{
        width: 100%;
    }

    .ContentMeData{
        padding-right: 5px;
        padding-left: 0px;
    }

    .ContentMeDataMessage{
        padding-right: 5px;
        padding-left: 0px;
    }
}
