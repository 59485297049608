.aboutMe{
    --bs-bg-opacity: 1;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    position: relative !important;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
}

.aboutMeContainer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    max-width: 1140px;
}

.titleAbout{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleAbout p{
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(3.5rem + 2.1vw);
    height: 30px;
    background-color: rgb(86, 173, 255);
    margin-bottom: 0px;
    font-size: 15px;
    color: white;
}

.titleAbout h2{
    font-size: 50px;
}

.AboutInfo{
    display: flex;
}

.AboutBody{
    padding-right: 50px;
    width: 60%;
}

.AboutName{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AboutName h2{
    margin-left: 10px;
    font-size: 50px !important;
    margin-bottom: 0px;
    font-weight: 200;
}

.AboutName h2 span{
    font-weight: 700;
    border-bottom: 3px solid rgb(86, 173, 255);
}

.AboutBody h3{
    font-size: 30px !important;
    font-weight: 400;
}

.expYear{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    flex-direction: column;
}

.expYear h1{
    width: 200px;
    height: 200px;
    background-color: rgb(47, 155, 255);
    border-radius: 50%;
    font-size: calc(7rem + 2.1vw) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.expText{
    display: flex;
    justify-content: center;
    align-items: center;
}

.expText h2{
    font-size: calc(0.1rem + 1vw) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}

.expText h3{
    font-size: calc(0.1rem + 1vw) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactData{
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallScreenData{
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallGroup{
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
}

.smallGroup span{
    font-size: calc(0.2rem + 0.5vw) !important;
    color: rgb(114, 114, 114);

}

.smallGroup p{
    margin-top: 0;
    font-size: calc(0.2rem + 0.5vw) !important;
    font-weight: 600;
}

.smallGroup a{
    color: black;
}

@media (max-width: 1920px) {

    .titleAbout p{
        font-size: calc(0.5rem + 0.5vw) !important;
    }

    .titleAbout h2 {
        font-size: calc(0.5rem + 2.1vw) !important;
    }

    .AboutInfo {
        display: flex;
    }

    .AboutBody{
        padding-right: 50px;
        width: 60%;
    }

    .AboutName{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    
    .AboutName h2{
        font-size: calc(0.5rem + 2.1vw) !important;
    }

    .AboutBody h3{
        font-size: calc(0.5rem + 1vw) !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contactData {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .smallScreenData {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .smallGroup {
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
    }

    .smallGroup span{
        font-size: calc(0.2rem + 1.2vw) !important;
        color: rgb(114, 114, 114);
    
    }
    
    .smallGroup p{
        margin-top: 0;
        font-size: calc(0.2rem + 1.2vw) !important;
        font-weight: 600;
    }

    .expText h2{
        font-size: calc(0.2rem + 2.1vw) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
    }
    
    .expText h3{
        font-size: calc(0.2rem + 2.1vw) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: calc(0.1rem + 1vw) !important;
    }
}

@media (max-width: 768px) {

    .titleAbout p{
        font-size: calc(0.5rem + 0.5vw) !important;
    }

    .titleAbout h2 {
        font-size: calc(0.8rem + 2.1vw) !important;
    }

    .AboutInfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .AboutBody{
        width: 100%;
        max-width: 100%;
        padding-right:0px;
    }

    .AboutName{
        justify-content: center;
    }

    
    .AboutName h2{
        font-size: calc(1rem + 2.1vw) !important;
    }

    .AboutBody h3{
        font-size: calc(0.5rem + 2.1vw) !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contactData {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .smallScreenData {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .smallGroup {
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
    }

    .smallGroup span{
        font-size: calc(0.7rem + 1.2vw) !important;
        color: rgb(114, 114, 114);
    }
    
    .smallGroup p{
        margin-top: 0;
        font-size: calc(0.7rem + 1vw) !important;
    }
}
