.featuresGroup{
    overflow: hidden;
    padding: 6.5rem 0;
    position: relative;
    box-sizing: border-box;
    background-color: rgb(248, 248, 248);
}

.featuresContainer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    max-width: 1200px;
}

.feabody{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.feaTitle{
    color: rgb(86, 172, 255);
    margin: 0;
}

.feaGroup{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.groupBtn{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(#ffffff,#e4e4e4);  
    background: -moz-linear-gradient(#ffffff,#e4e4e4);  
    background: -o-linear-gradient(#ffffff,#e4e4e4);  
    background: linear-gradient(#ffffff,#e4e4e4);  
    z-index: 2;
    color: rgb(0, 0, 0);
    max-width: 28%;
    padding: 2%;
    margin: 2%;
    box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);
    box-sizing: border-box;
    display: flex;
    height: calc(18rem + 2.1vw) !important;
    background-size: 200% 200%;

    transition: background 0.2s ease-out; /* vendorless fallback */
    -o-transition: background 0.2s ease-out; /* opera */
    -ms-transition: background 0.2s ease-out; /* IE 10 */
    -moz-transition: background 0.2s ease-out; /* Firefox */
    -webkit-transition: background 0.2s ease-out; /*safari and chrome */
}

.groupText{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    top: 0px;
    transition: top 0.2s linear; /* vendorless fallback */
    -o-transition: top 0.2s linear; /* opera */
    -ms-transition: top 0.2s linear; /* IE 10 */
    -moz-transition: top 0.2s linear; /* Firefox */
    -webkit-transition: top 0.2s linear; /*safari and chrome */
}

.groupText a{
    color: #2e98df;
    font-size: calc(0.5rem + 0.5vw) !important;
    font-weight: 600;
    text-decoration: none;
}

.groupText p{
    font-weight: 400;
    margin: 8px 0px;
}

.feaIcon{
    font-size: calc(0.2rem + 1.5vw) !important;
    color: rgb(0, 174, 255);
}

.ArrowIcon{
    font-size: calc(0.1rem + 1vw) !important;
    transition: color 0.2s linear; /* vendorless fallback */
    -o-transition: color 0.2s linear; /* opera */
    -ms-transition: color 0.2s linear; /* IE 10 */
    -moz-transition: color 0.2s linear; /* Firefox */
    -webkit-transition: color 0.2s linear; /*safari and chrome */

    color: rgba(0, 174, 255, 0);
}

.groupBtn:hover{
    /* -webkit-animation: Animation 1s ease infinite;
    -moz-animation: Animation 1s ease infinite;
    animation: Animation 1s ease infinite; */
    background-position:120px; 
}

.groupBtn:hover .groupText{
    /* animation-name: upANI;
    animation-duration: 0.2s;
    animation-fill-mode:forwards; */
    top: -15px;
    transition: top 0.2s linear; /* vendorless fallback */
    -o-transition: top 0.2s linear; /* opera */
    -ms-transition: top 0.2s linear; /* IE 10 */
    -moz-transition: top 0.2s linear; /* Firefox */
    -webkit-transition: top 0.2s linear; /*safari and chrome */
    
}

.groupBtn:hover .ArrowIcon{
    transition: color 0.2s linear; /* vendorless fallback */
    -o-transition: color 0.2s linear; /* opera */
    -ms-transition: color 0.2s linear; /* IE 10 */
    -moz-transition: color 0.2s linear; /* Firefox */
    -webkit-transition: color 0.2s linear; /*safari and chrome */
    color: rgb(0, 174, 255);
}

/* @keyframes upANI{
    from{
        top: 0px;
    }
    to{

        top: -15px;
    }
}


@keyframes upANI02{
    from{
        color: #00000000;
    }
    to{

        color: #000000;
    }
} */


@-webkit-keyframes Animation {
    0%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
    50%{background-position:91% 100%;box-shadow: 0px 0px 20px 14px rgb(222, 222, 222);}
    100%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
    50%{background-position:91% 100%;box-shadow: 0px 0px 20px 8px rgb(222, 222, 222);}
    100%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
}
@keyframes Animation { 
    0%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
    50%{background-position:91% 100%;box-shadow: 0px 0px 20px 8px rgb(222, 222, 222);}
    100%{background-position:10% 0%;box-shadow: 0px 0px 15px 2px rgb(222, 222, 222);}
}

@media (max-width: 768px) {
    .featuresGroup{
        padding: 2rem 0;
    }
    .featuresContainer {
        max-width: 300px;
    }
    .groupBtn{
        max-width: 100%;
        margin: 4% 0%;
        padding: 0% 4%;
    }

    .feaIcon{
        font-size: calc(1.5rem + 2.1vw) !important;
    }

    .groupText a {
        font-size: calc(1rem + 1vw) !important;
    }

    .ArrowIcon{
        font-size: calc(1.2rem + 2.1vw) !important;
    }
}