.PortFullScreen{
    overflow: hidden;
    padding: 6.5rem 0;
    position: relative;
    box-sizing: border-box;
    background-color: rgb(248, 248, 248);
}

.PortContainer{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    max-width: 1200px;
}

.PortTitle h4{
    color: rgb(86, 173, 255);
    margin: 0;
    font-weight: 400;
    font-size: 20px;
}

.PortTitle h2{
    font-size: 35px;
    margin-top: 15px;
}

.PorjectGroup{
    display: flex;
    margin-top: 70px;
    width: 100%;
}

.PortList{
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 8px rgb(230, 230, 230);
    width: 25%;
    z-index: 1;
}

.PortName{
    margin: 0px 0px;
    width: 100%;
}

.PortName button{
    width: 100%;
    height: 100px;
    font-size: 15px;
    font-weight: 600;
    color: rgb(80, 80, 80);
    background-color: rgba(240, 248, 255, 0);
    border: 0px;
}

.PortName button:hover, .PortName button:focus, .selectPort, .selectPort button{
    border-radius: 10px;
    box-shadow: 0px 0px 10px 8px rgba(86, 173, 255, 0.26);
    background:linear-gradient(#ffffff,#f1f1f1);
    color: rgb(86, 173, 255);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.PortData{
    width: 75%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 8px rgb(230, 230, 230);
    background:linear-gradient(#ffffff,#f1f1f1);
    z-index: 0;
    margin-left: 20px;
    overflow: hidden;
}

.SlickGroup{
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    background-color: rgb(248, 248, 248);
}

.Items{
    padding-top: 40px;
    padding-bottom: 130px;
    background-color: rgb(248, 248, 248);
}

.Items h2{
    margin: 20px 40px;
    border-bottom: 2px solid rgb(86, 173, 255);
    font-variant: small-caps
}

.Items h4{
    margin: 0 10px;
}

.Items button{
    padding: 0px;
    margin: 0px 0px;
    width: 100%;
    border: 0px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 8px 5px rgb(230, 230, 230);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
    -webkit-box-reflect: below -170px linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.151));
    
}

.itemImg{
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.Items img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.Items button:hover{
    transform: scale(1.05);
    box-shadow: 0px 0px 10px 8px rgb(226, 226, 226);
    transition: all 0.4s ease-out; /* vendorless fallback */
    -o-transition: all 0.4s ease-out; /* opera */
    -ms-transition: all 0.4s ease-out; /* IE 10 */
    -moz-transition: all 0.4s ease-out; /* Firefox */
    -webkit-transition: all 0.4s ease-out; /*safari and chrome */
}

.Items button:hover img{
    object-fit: cover;
    transition: all 0.4s ease-out; /* vendorless fallback */
    -o-transition: all 0.4s ease-out; /* opera */
    -ms-transition: all 0.4s ease-out; /* IE 10 */
    -moz-transition: all 0.4s ease-out; /* Firefox */
    -webkit-transition: all 0.4s ease-out; /*safari and chrome */
}

.ItemsData{
    margin: 15px 10px;
    height: 230px;
}

  
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.InfoFullScreen{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    background-color: rgba(0, 0, 0, 0.801);
    z-index: 100;
    box-sizing: border-box;
}

.InfoDataGroup{
    margin-top:100px;
    padding: 1.5rem 0;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    background-color: #ffffff;
    border-radius: 20px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    box-shadow: 0px 0px 10px 4px rgb(104, 104, 104);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.InfoDataGroup h2 {
    border-bottom: 2px solid rgb(86, 173, 255);
    padding-bottom: 10px;
}

.InfoClose{
    position: absolute;
    right: 0%;
    margin-right: 25px;
    border: 0px;
    background-color: #f1f1f100;
    padding: 0px;
}

.InfoClose i{
    font-size: 30px;
    color: #7a7a7a;
    padding: 2px;
}

.InfoClose i:hover{
    font-size: 30px;
    color: rgb(86, 173, 255);
}


.InfoClose i:active::before{
    box-shadow: 0px 0px 3px 1px rgb(86, 173, 255);
    border-radius: 10px;
}

.Info{
    display: flex;
    width: 100%;
}

.InfoSwiper{
    width: 600px;
    height: 600px;
    margin: 40px;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .source{
    object-fit: contain;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #e9e9e9;
  }

  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: white;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  .ProjectTechnologies{
    display: flex;
  }

  .InfoText{
    margin: 40px 0px;
    margin-right: 50px;
    border-bottom: 1px solid rgb(199, 199, 199);
  }

  .InfoText span{
    font-weight: 400;
  }

  .shareGroup a{
    margin: 0;
  }

  video{
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
  }

  .swiper-slide{
    background-color: #e9e9e9;
  }
  
@media (max-width: 820px) {
    .PorjectGroup{
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        margin-right: 20px;
    }

    .PortList{
        display: flex;
        flex-grow: 1;
        flex-basis: 200;
        overflow: hidden;
        margin-right: 20px;
    }

    .PortName button{
        font-size: calc(0.4rem + 1.5vw) !important;
        height: 40px;
    }

    .PortData{
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 20px;
        overflow: hidden;
        overflow-x: auto;
    }

    .InfoFullScreen{
        overflow-x: hidden;
        overflow-y: auto;
    }

    .InfoDataGroup{
        margin-top: 0px;
        margin-right: 50px;
        --bs-gutter-x: 0rem;
    }

    .Info{
        flex-wrap: wrap;
    }

    .InfoClose{
        top: 8%;
    }

    .InfoData{
        padding: 0 20px;
    }

    .InfoSwiper{
        width: 300px;
        height: 300px;
        position: relative;
        left: 50%;
        transform: translate(-65%, 0);
    }

    video{
        width: 300px;
    }
}