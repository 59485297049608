.fullscreen{
    min-height: 100vh !important;
    --bs-bg-opacity: 1;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    position: relative !important;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Conbody{
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto !important;
    margin-top: auto !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
}

.row {
    position: relative;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.textCentent{
    color: rgb(255, 255, 255);
    padding:0 30px;
    line-height: 30px;
    box-sizing: border-box;
    width: 600px;
    margin-bottom: 30px;
}

.textCentent h1{
    font-size: calc(0.6rem + 2.1vw) !important;
    font-weight: 100;
}

.textCentent h2{
    margin: 40px 0px;
}

.Typewriter span{
    font-size: calc(2.5rem + 2.1vw) !important;
}

.comeForm{
    font-size: calc(1px + 2.1vw) !important;
}

.Icon{
    display: flex;
    justify-content: center;
    align-items: center;

}

.Icon img{
    border-radius: 50%;
    border: 15px solid white;
    width: 60%;
    filter: grayscale(30%);
    box-shadow: 0px 0px 20px 6px rgb(95, 95, 95);
}

.ViewWork{
    display: flex;
    margin-top: 50px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.ViewWork button{
    background: linear-gradient(to right bottom, #212428, #16181c);
    color: white;
    width: 200px;
    height: 70px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 500;
    box-shadow: 0px 0px 8px 4px rgb(63, 63, 63);
    border: 0px solid #3e444b;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.ViewWork button:hover{
    background: linear-gradient(to right bottom, #3e444b, #16181c);
    box-shadow: 0px 0px 15px 4px rgb(63, 63, 63);
    border: 2px solid #ffffff;
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.contactMeBTN{
    margin-left: 100px;
    display: flex;
}

.contactMeBTN a{
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
}

.downArrow{
    position: relative;
    top: 0px;
}

.bi-arrow-down{
    position: relative;
    top: 0px;
    color: white;
    font-size: 20px;
    animation-name: downarrow;
    animation-duration: 2s;
    animation-iteration-count:infinite;
}

.bg {
    animation:slide 24s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, rgb(29, 29, 29) 50%, rgb(80, 80, 80) 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:0;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:25s;
  }
  
  .bg3 {
    animation-duration:26s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

@keyframes downarrow{
    from{
        top: 0px;
    }
    to{
        top: 20px;
    }
}

@media (max-width: 768px) {

    .textCentent {
        line-height: 10px;
        width:100%;
        margin-bottom: 0px;
    }

    .textCentent h1{
        font-size: 20px;
    }
    
    .textCentent h2{
        font-size: 40px;
    }

    .Typewriter span{
        font-size: calc(2.2rem + 1vw) !important;
    }
    
    .textCentent span{
        font-size: 10px;
    }
    
    .ViewWork{
        margin-top: 30px;
        margin-bottom: 30px;
        justify-content: center;
        flex-direction: column;
    }

    .ViewWork button{
        background: rgb(34, 34, 34);
        color: white;
        width: 150px;
        height: 40px;
        border: 0;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 500;
        box-shadow: 0px 0px 10px 4px rgb(70, 70, 70);
    }
    
    .contactMeBTN{
        margin-left: 0px;
        display: flex;
        height: 50px;
        align-items: flex-end;

    }
    
    .contactMeBTN a{
        color: white;
        font-size: 15px;
    }
}

@media (min-width: 1920px) {


    .Conbody{
        position: relative !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: auto !important;
        margin-top: auto !important;
        padding-bottom: 1.5rem !important;
        padding-top: 1.5rem !important;
        width: 100%;
    }

    .textCentent {
        width:100%;
        margin-bottom: 0px;
    }

    .textCentent h1{
        font-size: 20px;
    }
    
    .textCentent h2{
        font-size: 40px;
    }

    .Typewriter span{
        font-size: calc(2.2rem + 1vw) !important;
    }
    
    .textCentent span{
        font-size: 10px;
    }
    
    .ViewWork{
        margin-top: 70px;
        margin-bottom: 30px;
        justify-content: left;
    }

    .ViewWork button{
        background: rgb(34, 34, 34);
        color: white;
        width: 250px;
        height: 70px;
        border: 0;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 500;
        box-shadow: 0px 0px 10px 4px rgb(70, 70, 70);
    }
    
    .contactMeBTN{
        margin-left: 100px;
        display: flex;
        height: 50px;
        align-items: flex-end;

    }
    
    .contactMeBTN a{
        color: white;
        font-size: 15px;
    }

    .row{
        flex-wrap: nowrap;
    }

    .Icon img {
        border-radius: 50%;
        border: 15px solid white;
        width: 100%;
        filter: grayscale(30%);
        box-shadow: 0px 0px 20px 6px rgb(95, 95, 95);
    }
}