.ResFullScreen{
    overflow: hidden;
    padding: 6.5rem 0;
    position: relative;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
}

.ResContainer{
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
    width: 100%;
    max-width: 1400px;
}

.ResumeTitleName{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ResumeTitle{
    background-color: rgb(86, 173, 255);
    width: 100px;
    height: 30px;
}

.ResumeTitle p{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    color: white;
    font-size: 15px;
}

.MyEdEx{
    display:flex;
}

.EdExGroup{
    width: 50%;
    padding: 30px;
}

.EdExGroup h3{
    font-size: 25px;
}

.EdExData{
    border-left: 2px solid rgb(86, 173, 255);
}

.EdExInfo{
    margin-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(102, 102, 102, 0.397);
}

.EdExInfo h4{
    font-size: 20px;
}

.EdExInfo p{
    color: rgb(32, 32, 32);
    margin-bottom: 10px;
}

.EdExInfo span{
    margin-top: 0px;
    color: rgb(105, 105, 105);
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.MySkillsCon{
    margin-left: 20px;
    max-width: 1400px;
}

.MySkillsCon h2 {
    font-size: 25px;

}

.MySkillGroup {
    display: flex;

}

.MySkillList{
    width: 50%;
}

.SkillName{ 
    padding-right: 45px;
}

.SkillName h3{
    font-size: 20px;
    font-weight: 500;
}


@media (max-width: 768px) {
    .MyEdEx{
        flex-wrap: wrap;
    }

    .EdExGroup{
        width: 100%;
    }

    .MySkillGroup{
        flex-wrap: wrap;
    }

    .MySkillList{
        width: 100%;
    }
}
