body{
  background-color: #2b2b2b;
}

.App{
  background-color: #2b2b2b;
  height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button{
  cursor: pointer;
}

.container{
  display: flex;
  align-items: center;
  flex-wrap: inherit;
  justify-content: space-between;
  width: 100%;
}

.infoTitle{
  display: flex;
  box-shadow: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  height: auto;
  position: fixed;
}

.headerBackgroundBeginner{
  background-color: #16181c00;
  transition: all 0.2s ease-out; /* vendorless fallback */
  -o-transition: all 0.2s ease-out; /* opera */
  -ms-transition: all 0.2s ease-out; /* IE 10 */
  -moz-transition: all 0.2s ease-out; /* Firefox */
  -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.headerBackgroundScroll{
  background-color: #252525;
  transition: all 0.2s ease-out; /* vendorless fallback */
  -o-transition: all 0.2s ease-out; /* opera */
  -ms-transition: all 0.2s ease-out; /* IE 10 */
  -moz-transition: all 0.2s ease-out; /* Firefox */
  -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.imgFrame{
  margin: 5px 10px;
  display: flex;
  align-items: center;
  width: 30%;
}

.imgBox{
  display: flex;
  justify-content: center;
  align-items: left;
  width: 200px;
}

.imgBox img{
  width:30%;
  height: auto;
  border-radius: 20px;
  margin: 10px;
  background-color: white;
  border: 0px solid white;
  box-shadow: 0px 0px 12px 4px rgb(129, 129, 129);
}

.imgFrame p{
  color: white;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}

.detailInfo{
  margin: 0 30px;
  display: flex;
}

.detailInfo a{
  margin: 20px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.dropbtn {
  width: 50px;
  height: 50px;
  background-color: #3b3b3b;
  box-shadow: 0px 0px 8px 4px rgb(31, 31, 31);
  border-radius: 50%;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  margin-top: 7px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out; /* vendorless fallback */
  -o-transition: all 0.2s ease-out; /* opera */
  -ms-transition: all 0.2s ease-out; /* IE 10 */
  -moz-transition: all 0.2s ease-out; /* Firefox */
  -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333333;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f100}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: rgb(5, 184, 255);
  transition: all 0.2s ease-out; /* vendorless fallback */
  -o-transition: all 0.2s ease-out; /* opera */
  -ms-transition: all 0.2s ease-out; /* IE 10 */
  -moz-transition: all 0.2s ease-out; /* Firefox */
  -webkit-transition: all 0.2s ease-out; /*safari and chrome */
}
.detailMenu{
  display: none;
}

.textColor a:hover{
  animation-name:oxxo;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
}

.detailMenu button:hover{
  animation-name:buttonANI;
  animation-duration:0.5s;
  animation-fill-mode:forwards;
}

@media (max-width: 1408px) {
  .container, .container-md, .container-sm {
      max-width: 1400px;
  }

  .detailInfo{
    display: none;
  }

  .detailMenu{
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
  }

  .detailMenudropdown-content{
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    position: absolute;
    top: 70px;
    overflow: hidden;
    height: 0px;
    width: 120px;
    z-index: 100;
    box-shadow: 0px 0px 0px 0px rgb(70, 70, 70);
    background-color: #2c2c2c;
    border-radius: 5px;
    transition: height 0.5s ease-out; /* vendorless fallback */
    -o-transition: height 0.5s ease-out; /* opera */
    -ms-transition: height 0.5s ease-out; /* IE 10 */
    -moz-transition: height 0.5s ease-out; /* Firefox */
    -webkit-transition: height 0.5s ease-out; /*safari and chrome */
  }
  
  .detailMenu:hover .detailMenudropdown-content{
    height: 370px;
    box-shadow: 0px 0px 10px 4px rgb(70, 70, 70);
    transition: height 0.5s ease-out; /* vendorless fallback */
    -o-transition: height 0.5s ease-out; /* opera */
    -ms-transition: height 0.5s ease-out; /* IE 10 */
    -moz-transition: height 0.5s ease-out; /* Firefox */
    -webkit-transition: height 0.5s ease-out; /*safari and chrome */
  }

  .detailMenudropdown-content a{
    color: white;
    text-decoration: none;
    margin: 15px 10px;
    cursor: pointer;
    border-bottom: 0px solid rgba(5, 184, 255, 0);
  }

  .detailMenudropdown-content a:hover{
    color: rgb(5, 184, 255);
    border-bottom: 1px solid rgb(5, 184, 255);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
  }

  .detailMenudropdown-content a:active{
    color: rgb(5, 184, 255);
    border-bottom: 1px solid rgb(5, 184, 255);
    transition: all 0.2s ease-out; /* vendorless fallback */
    -o-transition: all 0.2s ease-out; /* opera */
    -ms-transition: all 0.2s ease-out; /* IE 10 */
    -moz-transition: all 0.2s ease-out; /* Firefox */
    -webkit-transition: all 0.2s ease-out; /*safari and chrome */
  }
  
  .lan{
    margin-top: 20px;
  }

  .detailMenu button{
    width: 50px;
    height: 50px;
    background: linear-gradient(to right bottom, #212428, #16181c);
    border: 0px;
    border-radius: 50%;
    font-size: 15px;
    color: white;
    box-shadow: 0px 0px 8px 4px rgb(63, 63, 63);
  }

  .imgFrame{
    margin: 0px 10px;
    display: flex;
    align-items: center;
    width: 30%;
  }

  .bi-list{
    font-size: 20px;
  }

  .imgBox img{
    width:40px;
  }
}


@keyframes oxxo{
  from{
      color: white;
  }
  to{
      color: rgb(5, 184, 255);
  }
}

@keyframes buttonANI{
  from{
    background: linear-gradient(to right bottom, #212428, #16181c);
    box-shadow: 0px 0px 8px 4px rgb(63, 63, 63);
  }
  to{
    background: linear-gradient(to right bottom, #27282b, #16181c);
    box-shadow: 0px 0px 15px 10px rgb(63, 63, 63);
  }
}
